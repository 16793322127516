
import { defineComponent, computed } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "Publications",
  setup() {
    const { t } = i18n.global;

    const list = computed(() => [
      // {
      //   title: t("publications.articles.a.title"),
      //   subtitle: t("publications.articles.a.subtitle"),
      //   file: t("publications.articles.a.file")
      // },
      // {
      //   title: t("publications.articles.b.title"),
      //   subtitle: t("publications.articles.b.subtitle"),
      //   file: t("publications.articles.b.file")
      // },
      {
        title: t("publications.articles.c.title"),
        subtitle: t("publications.articles.c.subtitle"),
        file: t("publications.articles.c.file")
      },
      // {
      //   title: t("publications.articles.d.title"),
      //   subtitle: t("publications.articles.d.subtitle"),
      //   file: t("publications.articles.d.file")
      // },
      {
        title: t("publications.articles.e.title"),
        subtitle: t("publications.articles.e.subtitle"),
        file: t("publications.articles.e.file")
      },
      {
        title: t("publications.articles.f.title"),
        subtitle: t("publications.articles.f.subtitle"),
        file: t("publications.articles.f.file")
      },
      {
        title: t("publications.articles.g.title"),
        subtitle: t("publications.articles.g.subtitle"),
        file: t("publications.articles.g.file")
      }
    ]);

    const thumbClick = (idx: number) => {
      const item = list.value[idx];
      const imageRepo =
        "https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=doc&file=publications/";
      const fileUrl = imageRepo + item["file"];
      window.open(fileUrl);
    };

    return {
      t,
      list,
      thumbClick
    };
  }
});
